import {
  Button,
  CloseIcon,
  IconButton,
  MenuIcon,
  Typo,
} from '@dreipol/pusch-components';
import { useMediaQuery } from '@dreipol/t3-react-media';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import React, {
  cloneElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Headroom from 'react-headroom';
import { useGlobalSettings } from 'src/context/global-settings-context';
import { Container } from '../container/container';
import classes from './header.module.scss';
import { useRouter } from 'next/dist/client/router';

export type HeaderProps = PropsWithChildren<{
  /**
   * Custom language switcher component
   */
  languageSwitcher?: ReactNode;

  /**
   * Custom classname
   */
  className?: string;
}>;

export const Header = ({
  children,
  languageSwitcher,
  className,
}: HeaderProps) => {
  const isMaxMd = useMediaQuery(['md', 'sm', 'xs']);
  const { t } = useTranslation();
  const [navOpen, setNavOpen] = useState(false);
  const [lastPagePracticeMap, setLastPagePracticeMap] = useState(false);
  const globalSettings = useGlobalSettings();
  const router = useRouter();

  const navigation = useMemo(
    () => (
      <div className={clsx(classes.navContainer)}>
        <nav className={classes.nav}>
          <Link
            className={classes.navItem}
            href={{
              pathname: router.pathname,
              query: {
                ...(router.query['slug'] && { slug: router.query['slug'] }),
                practiceMap: true,
              },
            }}
            scroll={false}
            shallow={true}
          >
            <Typo className={classes.navItem} color={'secondary'}>
              {t('header.links-practice-map')}
            </Typo>
          </Link>
          {React.Children.map(children as ReactElement, (child: ReactElement) =>
            cloneElement(child, {
              ...child.props,
              className: clsx(child.props.className, classes.navItem),
              onClick: (e: Event) => {
                child.props.onClick?.(e);
                setNavOpen(false);
              },
            }),
          )}
        </nav>
        <Link
          className={classes.mobileCtaLink}
          href={globalSettings.donationSlug}
          aria-label={t('cta-donate')!}
        >
          <Button
            onClick={() => setNavOpen(false)}
            color={'primary'}
            href={globalSettings.donationSlug}
            fullWidth
            className={classes.mobileNavCta}
          >
            {t('cta-donate')}
          </Button>
        </Link>
      </div>
    ),
    [isMaxMd, children, t],
  );

  /**
   * Handle scroll lock
   */
  useEffect(() => {
    const body = document.body;
    if (navOpen) {
      body.style.overflow = 'hidden';
      body.style.position = 'absolute';
      body.style.width = '100%';
    } else {
      body.style.removeProperty('overflow');
      body.style.removeProperty('position');
      body.style.removeProperty('width');
    }
  }, [navOpen]);

  /**
   * Calculates vh for mobile browsers
   */
  useEffect(() => {
    if (isMaxMd) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  });

  useEffect(() => {
    if (lastPagePracticeMap) {
      setNavOpen(false);
    }
    setLastPagePracticeMap(router.asPath.includes('practiceMap=true'));
  }, [router]);

  return (
    <Headroom className={classes.root}>
      <Container className={clsx(classes.headerBar, className)}>
        <div className={classes.leftAlign}>
          <Link className={classes.logo} href="/" aria-label={'Home'}>
            <Image
              width={isMaxMd ? 40 : 56}
              height={isMaxMd ? 40 : 56}
              src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/imgs/header/logo.svg`}
              alt="Logo"
            />
            <Typo variant="h6" as="h6" color="secondary">
              {t('header.logo')}
            </Typo>
          </Link>
        </div>
        <div className={classes.navDesktop}>{navigation}</div>
        <div className={classes.rightAlign}>
          {!navOpen && (
            <Image
              className={classes.anniversaryBanner}
              width={isMaxMd ? 74 : 98}
              height={0}
              src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/imgs/header/pusch-jubilaeum-stoerer-${router.locale}.svg`}
              alt="Anniversary Banner"
            />
          )}
          <div
            className={clsx(classes.languageSwitcher, {
              [classes.languageSwitcherOpen]: navOpen,
            })}
          >
            {languageSwitcher}
          </div>

          <IconButton
            className={classes.navToggle}
            onClick={() => setNavOpen(!navOpen)}
            variant="plain"
          >
            {navOpen ? <CloseIcon size="large" /> : <MenuIcon size="large" />}
          </IconButton>
          <Link
            href={globalSettings.donationSlug}
            aria-label={t('cta-donate')!}
          >
            <Button className={classes.cta} color={'primary'}>
              {t('cta-donate')}
            </Button>
          </Link>
        </div>
      </Container>
      <div className={classes.navMobile}>{navOpen && navigation}</div>
    </Headroom>
  );
};
